import React, { useState, useRef, useEffect, ChangeEvent, FocusEvent, KeyboardEvent } from "react";

interface ExpandableTextareaProps {
	value: string;
	onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
	onHeightChange?: (height: number) => void;
	onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
	placeholder?: string;
	maxLines?: number;
	className?: string;
}

const ExpandableTextarea: React.FC<ExpandableTextareaProps> = ({
	value,
	onChange,
	onFocus,
	onHeightChange,
	onKeyDown,
	placeholder = "",
	maxLines = 5,
	className = "",
}) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const [height, setHeight] = useState<number>(60); // Initialize with minimum height

	useEffect(() => {
		adjustTextareaHeight();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const adjustTextareaHeight = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			const computedStyle = window.getComputedStyle(textarea);
			const lineHeight = parseInt(computedStyle.lineHeight);
			const paddingTop = parseInt(computedStyle.paddingTop);
			const paddingBottom = parseInt(computedStyle.paddingBottom);
			const maxHeight = lineHeight * maxLines + paddingTop + paddingBottom;

			textarea.style.height = "auto";

			const newHeight = Math.min(textarea.scrollHeight, maxHeight);
			textarea.style.height = `${newHeight}px`;

			if (newHeight !== height) {
				setHeight(newHeight);
				if (onHeightChange) {
					onHeightChange(newHeight);
				}
			}
		}
	};

	const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		if (onChange) {
			onChange(event);
		}
		adjustTextareaHeight();
	};

	return (
		<textarea
			ref={textareaRef}
			rows={1}
			value={value}
			onChange={handleChange}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			placeholder={placeholder}
			className={`placeholder:text-muted-foreground flex w-full resize-none overflow-y-auto text-wrap rounded-md border-0 bg-transparent px-3 py-5 text-sm text-gray-900 focus:outline-none focus:ring-0 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 ${className}`.replace(
				"removeTopPadding2",
				"removeTopPadding",
			)}
			style={{ minHeight: "40px", height: `${height}px`, fontSize: "18px" }}
		/>
	);
};

export default ExpandableTextarea;
