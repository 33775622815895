"use client";
import React, { useState } from "react";

import Checkbox from "../../lib/ui/components/CheckBox";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoSuggestInput from "./AutoSuggestInput";
import { baseUrl, serverUrls } from "src/dataMockups/constants";

interface AdvancedSearchSectionProps {
	setState: React.Dispatch<React.SetStateAction<any>>;
	state: any;
	textColor: string;
	isHomePage: boolean;
	previousAuthorSearches: string[];
	previousJournalSearches: string[];
	addAuthorSearch: (search: string) => void;
	addJournalSearch: (search: string) => void;
	handleCheckboxChange: (label: string) => void;
}

const AdvancedSearchSection: React.FC<AdvancedSearchSectionProps> = ({ setState, state, textColor, isHomePage }) => {
	// Function to handle the checkbox change

	const [authorSuggestions, setAuthorSuggestions] = useState<string[]>([]);
	const [journalSuggestions, setJournalSuggestions] = useState<string[]>([]);

	// Function to clear suggestions when switching between inputs
	// const handleInputBlur = (inputType: "author" | "journal") => {
	// 	if (inputType === "author") {
	// 		setAuthorSuggestions([]);
	// 	} else if (inputType === "journal") {
	// 		setJournalSuggestions([]);
	// 	}
	// };
	const handleArticleTypeChange = (label: string) => {
		// If "All" is toggled
		if (label === "All") {
			const newArticleTypes = { ...state.articleTypes };
			const isAllChecked = !state.articleTypes.All; // Toggle "All"

			// Set all article types based on "All" state
			Object.keys(newArticleTypes).forEach((key) => {
				newArticleTypes[key] = isAllChecked;
			});

			setState((prevState: any) => ({
				...prevState,
				articleTypes: newArticleTypes,
			}));
		} else {
			// For individual checkboxes
			setState((prevState: any) => ({
				...prevState,
				articleTypes: {
					...prevState.articleTypes,
					[label]: !prevState.articleTypes[label],
					// Uncheck "All" if any other checkbox is unchecked
					All: false,
				},
			}));
		}
	};

	return (
		<div className="mx-auto grid h-fit w-[95%] grid-cols-1 gap-3 border-b border-b-white py-5 md:grid-cols-2 md:gap-10 lg:w-full">
			{/* Select boxes */}
			<div className="flex h-full w-full flex-col gap-2">
				<AutoSuggestInput
					apiUrl={`${baseUrl}${serverUrls.authorAutoSuggest}`}
					placeHolder="Enter an author name"
					searchValue={state.author}
					setSearchValue={(value) => setState((prevState: any) => ({ ...prevState, author: value }))}
					suggestions={authorSuggestions}
					setSuggestions={setAuthorSuggestions}
					// handleBlur={() => handleInputBlur("author")} // Clear suggestions when input loses focus
				/>
				<AutoSuggestInput
					apiUrl={`${baseUrl}${serverUrls.journalAutoSuggest}`}
					placeHolder="Enter a journal name"
					searchValue={state.journal}
					setSearchValue={(value) => setState((prevState: any) => ({ ...prevState, journal: value }))}
					suggestions={journalSuggestions}
					setSuggestions={setJournalSuggestions}
					// handleBlur={() => handleInputBlur("journal")} // Clear suggestions when input loses focus
				/>

				<div id="date-range-picker" className="flex items-center">
					<div className="relative">
						<DatePicker
							dateFormat="yyyy"
							showYearPicker
							selected={state.startDate}
							onChange={(date) => setState((prevState: any) => ({ ...prevState, startDate: date }))}
							placeholderText="Select date start"
							className="z-[3] block h-[40px] w-full rounded border border-primaryBlue bg-gray-50 p-2.5 text-center text-sm text-primaryGray focus:border-primaryBlue focus:ring-primaryBlue"
						/>
					</div>
					<span className={`mx-4 ${textColor}`}>to</span>
					<div className="relative">
						<DatePicker
							dateFormat="yyyy"
							showYearPicker
							selected={state.endDate}
							onChange={(date) => setState((prevState: any) => ({ ...prevState, endDate: date }))}
							placeholderText="Select date end"
							className="z-[3] block h-[40px] w-full rounded border border-primaryBlue bg-gray-50 p-2.5 text-center text-sm text-primaryGray focus:border-primaryBlue focus:ring-primaryBlue"
						/>
					</div>
				</div>
			</div>
			{/* Checkboxes */}
			<div className="flex h-full w-full flex-col gap-4">
				<span className={`${textColor} text-sm uppercase`}>Article Type</span>
				{Object.keys(state.articleTypes).map((label) => (
					<div key={label} className="flex items-center gap-1 pl-4">
						<Checkbox
							id={`checkbox-${label}`}
							textColor={isHomePage ? "" : "primaryTurquoise"}
							checked={state.articleTypes[label]}
							label={label}
							onChange={() => handleArticleTypeChange(label)}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default AdvancedSearchSection;
