import React from "react";

// UNCOMMENT CHECKED WHEN NEEDED

interface CheckboxProps {
	textColor?: string;
	id: string;
	label?: string;
	disabled?: boolean;
	name?: string;
	checked?: boolean;
	[key: string]: any; // To allow additional props
}

const Checkbox: React.FC<CheckboxProps> = ({
	id,
	textColor,
	label,
	checked = false,
	name = "Checkbox",
	disabled = false,
	...props
}) => {
	return (
		<div className="flex items-center">
			<input
				id={id}
				type="checkbox"
				disabled={disabled}
				className={`h-5 w-5 rounded border-DeepTurquoise focus:outline-none focus:ring-4 focus:ring-DeepTurquoise focus:ring-opacity-25 ${disabled ? "bg-gray-400 text-gray-400" : "bg-white text-DeepTurquoise"}`}
				name={name}
				checked={checked}
				{...props}
			/>
			{label && (
				<label
					htmlFor={id}
					className={`ml-2 text-sm ${disabled ? "text-gray-600" : textColor ? `text-${textColor}` : "text-white"}`}
				>
					{label}
				</label>
			)}
		</div>
	);
};

export default Checkbox;
