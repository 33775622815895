import React from "react";

interface RadioButtonProps {
	id: string;
	label?: string;
	disabled?: boolean;
	name: string;
	value: string | number;
	checked?: boolean;
	variant?: "primary" | "secondary" | "tertiary"; // Add new variants here
	[key: string]: any; // To allow additional props
}

const RadioButton: React.FC<RadioButtonProps> = ({
	id,
	label,
	value,
	name,
	checked = false,
	disabled = false,
	variant = "primary",
	...props
}) => {
	const variantClasses: Record<string, { input: string; label: string }> = {
		primary: {
			input:`h-4 w-4 text-2xl ${disabled ? "text-gray-500" : checked?  "text-DeepTurquoise cursor-pointer": "text-white bg-white cursor-pointer" } ring-0 focus:ring-DeepTurquoise ring-DeepTurquoise checked:ring-0 checked:ring-DeepTurquoise focus:outline-none disabled:ring-gray-500`,
			label: "text-DeepTurquoise",
		},
		secondary: {
			input:`h-4 w-4 text-2xl ${disabled ? "text-gray-500" : "text-primaryOrangeDark  cursor-pointer"} ring-0 focus:ring-SecondaryTurquoise ring-SecondaryTurquoise checked:ring-0 checked:ring-SecondaryTurquoise focus:outline-none disabled:ring-gray-500`,
			label: "text-white",
		},
		tertiary: {
			input: "border-red-500 bg-red-500 text-red-500 checked:ring-red-500",
			label: "text-red-500",
		},
	};

	const selectedClasses = variantClasses[variant] || variantClasses["primary"];

	return (
		<div className="flex items-center">
			<input
				id={id}
				type="radio"
				value={value}
				name={name}
				checked={checked}
				disabled={disabled}
				className={`mr-[2px] h-5 w-5 appearance-none border-2 border-gray-200 bg-transparent ${selectedClasses.input} ${
					disabled ? "text-gray-400" : ""
				} focus:ring-0`}
				{...props}
			/>
			{label && (
				<label htmlFor={id} className={`ms-1 text-sm ${disabled ? "text-gray-600" : selectedClasses.label}`}>
					{label}
				</label>
			)}
		</div>
	);
};

export default RadioButton;