import { useCallback, useEffect, useState, ChangeEvent, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { FiLoader } from "react-icons/fi";

interface AutoSuggestInputProps {
	setSearchValue: (value: string) => void;
	searchValue: string;
	placeHolder: string;
	apiUrl: string;
	suggestions: string[];
	setSuggestions: React.Dispatch<React.SetStateAction<string[]>>;
	handleBlur?: () => void;
}

function AutoSuggestInput({
	setSearchValue,
	searchValue,
	placeHolder,
	apiUrl,
	suggestions,
	setSuggestions,
	handleBlur,
}: AutoSuggestInputProps) {
	const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const inputRef = useRef<HTMLDivElement>(null);
	// Fetch suggestions when input value changes
	const fetchSuggestions = useCallback(
		async (inputValue: string) => {
			if (inputValue.trim() === "") {
				setSuggestions([]);
				setLoading(false);
				return;
			}
			setLoading(true);
			console.log("Fetching suggestions for:", inputValue);

			try {
				const response = await fetch(`${apiUrl}${inputValue}`);
				const data = await response.json();
				setSuggestions(data); // Assuming the API returns a list of strings
			} catch (error) {
				console.error("Failed to fetch suggestions:", error);
			} finally {
				setLoading(false);
				console.log("Finished fetching suggestions.");
			}
		},
		[apiUrl, setSuggestions],
	);

	// Debounced fetchSuggestions function
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (showSuggestions && searchValue) {
				console.log("Debounced input:", searchValue);
				fetchSuggestions(searchValue);
			}
		}, 300);

		return () => clearTimeout(delayDebounceFn); // Clear timeout if input changes
	}, [searchValue, showSuggestions, fetchSuggestions]);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setSearchValue(value);
		setShowSuggestions(true);
		setSuggestions([]); // Clear suggestions while typing
	};

	// Close suggestions on blur
	const handleBlurEvent = () => {
		if (handleBlur) handleBlur();
		setShowSuggestions(false);
	};

	return (
		<div className="relative block w-full" ref={inputRef}>
			<input
				onChange={handleInputChange}
				// onBlur={handleBlurEvent}
				onFocus={() => setShowSuggestions(true)} // Open suggestions on focus
				value={searchValue}
				type="text"
				name={placeHolder}
				id={placeHolder}
				placeholder={placeHolder}
				className="z-10 block h-[48px] w-full rounded bg-white p-2.5 text-sm text-gray-900 focus:border-primaryBlue focus:ring-primaryBlue"
			/>
			<button onClick={() => setShowSuggestions(!showSuggestions)} className="absolute bottom-0 right-5 top-0">
				<IoIosArrowDown color="#596774" className={`text-lg ${showSuggestions ? "rotate-180" : "rotate-0"}`} />
			</button>
			{/* Show loader while loading */}
			{loading && (
				<div className="absolute left-0 right-0 z-20 mt-1 flex h-full max-h-36 min-h-20 w-full items-center justify-center overflow-auto rounded border border-gray-300 bg-white">
					<FiLoader className="animate-spin text-xl text-primaryBlue" />
				</div>
			)}
			{/* Show suggestions if available and loading is false */}
			{suggestions.length > 0 && showSuggestions && !loading && (
				<ul className="absolute left-0 right-0 z-20 max-h-36 w-full overflow-auto rounded border border-gray-300 bg-white">
					{suggestions.map((suggestion, index) => (
						<li
							key={index}
							onClick={() => {
								setSearchValue(suggestion);
								setSuggestions([]); // Clear suggestions after selecting
								setShowSuggestions(false);
							}}
							className="flex cursor-pointer flex-row items-center gap-2 p-2 px-2 py-2 duration-500 hover:bg-gray-200"
						>
							<span>{suggestion}</span>
						</li>
					))}
				</ul>
			)}
		</div>
	);
}

export default AutoSuggestInput;
