import { useState } from "react";

function usePreviousSearches(key:string, initialValue:string[]) {
	const isBrowser = typeof window !== "undefined";

	const [storedValue, setStoredValue] = useState(() => {
		if (!isBrowser) return initialValue; // Return initial value during SSR
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.error(error);
			return initialValue;
		}
	});

	const setValue = (value: string[]) => {
		try {
			setStoredValue(value);
			if (isBrowser) {
				window.localStorage.setItem(key, JSON.stringify(value));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const addValue = (value: string) => {
		setValue([value, ...storedValue.filter((item: string) => item !== value)].slice(0, 10)); // Limit to 5 searches
	};

	return [storedValue, addValue];
}

export default usePreviousSearches;
